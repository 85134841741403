import { createStyles, makeStyles } from "@material-ui/core";
import logo from "../src/img/icon.png";
import React, { FC } from "react";

interface Term {
    name: string;
    descriptions: string[];
}

const App: FC = () => {
    const terms: Term[] = [
        {
            name: "앱 소개",
            descriptions: [
                "Ycog는 자폐 스펙트럼 장애(Autism spectrum Disorders)아동·청소년의 얼굴 및 정서 인식과 사회성 향상을 위해 개발된 훈련 프로그램 앱입니다.",
            ],
        },
        {
            name: "앱 사용",
            descriptions: [
                "연세대학교 디지털 정신건강 연구실은 의료 기기 또는 서비스 제공에 관여하지 않습니다. 따라서 본 앱의 용도는 전문 의학적 진단 또는 치료를 대체하는 것과 무관합니다.",
                "사용자는 본 앱을 둘러보고, 앱에 포함된 모든 훈련 프로그램을 사용할 수 있습니다. 본 앱은 개인의 훈련을 위한 용도로만 제공됩니다.",
                "연세대학교 디지털 정신건강 연구실의 사전 서면 승인(동의)이 없는 Ycog 콘텐츠 일부 혹은 전체의 상업적·비상업적 무단사용은 엄격히 금지됩니다.",
                "모든 사용자는 본 앱을 실행할 때 정확한 최신의 개인정보만을 등록해야 합니다.",
            ],
        },
        {
            name: "지식재산권",
            descriptions: [
                "본 앱, 앱에 포함된 디자인, 로고, 콘텐츠 및 다른 모든 구성요소와 관련된 저작권, 디자인권, 특허권, 상표권 및 그 외의 일체의 권리들은 모두 본 앱을 개발한 연세대학교 디지털 정신건강 연구실에 있습니다.",
                "모든 사용자는 연세대학교의 지식재산권 등의 권리를 존중하고 이 권리 보호를 위한 관련 법률을 준수해야만 합니다.",
                "연세대학교 디지털 정신건강 연구실의 사전 서면 승인이 없는 본 앱 및 앱에 포함된 자료에 관한 일체의 무단복제, 무단사용 또는 무단배포는 엄격히 금지됩니다.",
                "연세대학교 디지털 정신건강 연구실은 본 앱에 관련된 지식재산권 등의 위조행위에 대해 법적 조치를 취할 권한이 있습니다.",
            ],
        },
        {
            name: "개인정보 보호",
            descriptions: [
                "사용자가 본 앱을 사용하는 과정에서 기록된 개인정보(생년월일, 나이, 성별, 진단명), 아이디, 닉네임, 설문 자료, 사용한 핸드폰 기종, 훈련 관련 데이터(음성녹음파일, 사진파일 등 포함)등에 관한 정보는 연세대학교 디지털 정신건강 연구실에서 수집·관리하며 연구 목적 이외의 용도로는 사용하지 않습니다. 이 정보는 연구를 위해 5년 간 보관되며 수집된 정보는 개인정보보호법에 따라 적절히 관리됩니다. 사용자들의 개인정보는 잠금 장치가 있는 보안컴퓨터에 의해 안전하게 보관되며 오직 연세대학교 디지털 정신건강 연구실 연구원에 의해서만 접근 가능합니다. 연세대학교 디지털 정신건강 연구실은 본 앱을 통해 얻은 모든 개인 정보의 비밀보장을 위해 최선을 다할 것입니다.",
            ],
        },
        {
            name: "수정",
            descriptions: [
                "연세대학교 디지털 정신건강 연구실은 본 앱을 사전 통지 없이 비정기적으로 업데이트할 수 있습니다. 따라서 사용자는 본 앱에 접속하기 위해서 자신의 기기에 업데이트에 따른 변경을 해야 할 필요가 있을 수 있으며, 이러한 변경사항을 적용하지 않을 경우 본 앱의 사용에 제한이 있을 수 있습니다. 이러한 경우 연세대학교 디지털 정신건강 연구실은 이용제한에 대해 책임을 지지 않으며, 본 앱의 유지 관리 및 지원 서비스를 제공할 의무도 없습니다. 또한 연세대학교 디지털 정신건강 연구실은 이용약관에 필요한 수정을 할 수 있고, 새로운 약관이나 추가된 약관을 적용할 권한이 있습니다. 본 앱을 계속 사용하는 경우 이러한 수정사항에 동의하는 것으로 간주되며, 수정 또는 추가된 약관은 현행 약관에 통합되어 즉시 효력이 발생합니다.",
            ],
        },
        {
            name: "제작진 소개",
            descriptions: [
                "- 제작기관: 연세대학교 디지털 정신건강 연구실 (디자인 기획: 박종표, 일러스트 제작: 석현지)",
                "- 지원기관: 본 사업은 보건복지부의 재원으로 한국보건산업진흥원의 보건의료기술 연구개발사업 지원에 의하여 이루어진 것입니다. (과제번호: HI15C0817)",
                "- 본 앱은 삼성전자 주식회사가 재정적으로 지원하고 연세대학교와 분당서울대병원에서 개발한 “룩앳미”어플리케이션의 콘텐츠 일부를 포함하고 있습니다. “룩앳미”어플리케이션에 관한 일체의 권리는 삼성전자 주식회사가 가지고 있습니다.",
            ],
        },
        {
            name: "프로그램 문의",
            descriptions: [
                "사용자는 Ycog앱을 사용하면서 어려움이 있거나 문의사항이 있을 경우 다음의 이메일로 문의하실 수 있습니다.",
                "- 연세대학교 행동심리 연구실: ysuniv.clinicalpsy@gmail.com",
            ],
        },
    ];

    const classes = useStyles();

    return (
        <div>
            <header className={classes.header}>
                <img src={logo} alt={"Ycog logo"} style={{ height: 60 }} />
                <h1>이용약관</h1>
            </header>
            <main className={classes.main}>
                <dl>
                    {terms.map((term, i) => (
                        <React.Fragment key={i}>
                            <dt className={classes.title}>
                                {i + 1}. {term.name}
                            </dt>
                            <dd className={classes.desc}>
                                {term.descriptions.map((desc, i) => (
                                    <p key={i}>{desc}</p>
                                ))}
                            </dd>
                        </React.Fragment>
                    ))}
                </dl>
            </main>
            <footer className={classes.footer}>
                <p>
                    <strong>Ycog</strong>
                </p>
                <p>kmchung@yonsei.ac.kr</p>
                <p>Addr. (Yonsei University)50, Yonsei-ro, Seodaemun-gu, Seoul, Republic of Korea Widang Hall, 608</p>
                <p>Copyright ⓒ Kyongmee Chung 2021 Inc. All rights reserved.</p>
            </footer>
        </div>
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        header: {
            height: 90,
            marginLeft: 30,
            marginRight: 30,
            display: "flex",
            alignItems: "center",
            borderBottom: "solid 1px lightgray",
            "& > *": {
                margin: 0,
                fontSize: 20,
                fontWeight: 900,
            },
        },
        main: {
            padding: "0px 150px 30px 30px",
        },
        footer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: 12,
            color: "#ffffff",
            textAlign: "center",
            height: 200,
            backgroundColor: "#383A3F",
            "& > *": {
                margin: 5,
            },
        },
        title: {
            marginTop: 30,
            fontSize: 16,
            fontWeight: 600,
        },
        desc: {
            fontSize: 14,
        },
    }),
);

export default App;
